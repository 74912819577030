import api from "../../../base/utils/request";

// 获取自定义组件库列表
export const componentLibApi = (data) => {
  return api({
    url: '/admin/app_design/component/index',
    method: 'post',
    data,
    notCancel: true
  })
}

// 获取通讯录筛选会员资料配置
export const SelectMemberFields = (data) => {
  return api({
    url: '/admin/cyc/apply_template/selectFields',
    method: 'post',
    data,
    notCancel: true
  })
}


// 获取链接类型配置
export const linkTypeList = (data) => {
  return api({
    url: '/admin/app_design/link/index2',
    method: 'post',
    data,
    notCancel: true
  })
}

// 获取链接列表
export const linkList = (data) => {
  return api({
    url: '/admin/app_design/link/data',
    method: 'post',
    data,
    notCancel: true
  })
}

// 获取来源选项
export const AllSource = data => {
  return api({
    url: "/admin/portal/source/all",
    method: "post",
    data,
    notCancel: true
  });
};

// 获取品牌列表
export const brandList = (data, notCancel = true) => {
  return api({
    url: "/admin/cyc/brand/componentSearch",
    method: "post",
    data,
    notCancel
  });
};

// 获取多层级列表
export const multiLevelList = (data, notCancel = true) => {
  return api({
    url: "/admin/cyc/multi_level/componentSearch",
    method: "post",
    data,
    notCancel
  });
};

// 获取会员风采列表
export const spiritList = (data, notCancel = true) => {
  return api({
    url: "/admin/cyc/spirit/componentSearch",
    method: "post",
    data,
    notCancel
  });
};

// 获取活动列表
export const activityList = (data, notCancel = true) => {
  return api({
    url: "/admin/cyc_activity/activity/componentSearch",
    method: "post",
    data,
    notCancel
  });
};

// 获取活动相册列表
export const activityPhotoList = (data, notCancel = true) => {
  return api({
    url: "/admin/cyc_activity/component/index",
    method: "post",
    data,
    notCancel
  });
};

// 获取文章分类联级数据
export const getAllCategories = data => {
  return api({
    url: '/admin/portal/category/allShow',
    method: 'post',
    data,
    notCancel: true
  })
}

// 获取文章列表
export const articleList = (data, notCancel = true) => {
  return api({
    url: '/admin/portal/article/componentSearch',
    method: 'post',
    data,
    notCancel
  })
}

// 获取入会申请架构
export const organizationList = data => {
  return api({
    url: '/admin/cyc/organization/componentSearch',
    method: 'post',
    data,
    notCancel: true
  })
}

// 获取直播列表
export const liveList = (data, notCancel = true) => {
  return api({
    url: '/admin/wx_open/wx_live/liveComponentSearch',
    method: 'post',
    data,
    notCancel
  })
}

// 获取文章专题列表
export const specialArticleList = (data, notCancel = true) => {
  return api({
    url: '/admin/portal/subject/componentSearch',
    method: 'post',
    data,
    notCancel
  })
}

// 获取搜索内容选项
export const searchTypes = data => {
  return api({
    url: '/admin/app_design/component/searchTypes',
    method: 'post',
    data,
    notCancel: true
  })
}

// 获取信息展示选项内容
export const componentSelect = data => {
  return api({
    url: '/admin/cyc/apply_template/componentSelect',
    method: 'post',
    data,
    notCancel: true
  })
}

export const infoComponentSelect = data => {
  return api({
    url: '/admin/cyc/apply_template/infoComponentSelect',
    method: 'post',
    data,
    notCancel: true
  })
}

// 获取文章专题分类
export const articleCategory = data => {
  return api({
    url: '/admin/portal/category/all',
    method: 'post',
    data,
    notCancel: true
  })
}

// 获取资源分类
export const circleCategory = data => {
  return api({
    url: '/admin/cyc_circle/category/all',
    method: 'post',
    data,
    notCancel: true
  })
}

// 获取商品列表
export const goodsList = (data, notCancel = true) => {
  return api({
    // url: '/admin/wx_open/wx_store_goods/componentSearch',
    url: '/admin/shop/goods/componentSearch',
    method: 'post',
    data,
    notCancel
  })
}

// 获取小商店分类选项
export const getAllShopCategory = data => {
  return api({
    url: '/admin/wx_open/wx_store_category/componentSelect',
    method: 'post',
    data,
    notCancel: true
  })
}

// 获取小商店商品分类选项
export const storeCategory = data => {
  return api({
    url: '/admin/wx_open/wx_store_category/all',
    method: 'post',
    data,
    notCancel: true
  })
}
// 获取组织架构会员身份是否展示的数据
export const getMemberIdentity = data => {
  return api({
    url: '/admin/cyc/settings/getCycApplyRoleConfig',
    method: 'post',
    data,
    notCancel: true
  })
}


// 获取联系我插件客服类型（单人/多人）
export const ContactInfo = data => {
  return api({
    url: '/admin/wx_open/wx_company/getContactInfo',
    method: 'post',
    data,
    notCancel: true
  })
}

// 获取企微插件状态
export const getPluginStatus = data => {
  return api({
    url: '/admin/wx_open/wx_company/getPluginStatus',
    method: 'post',
    data,
    notCancel: true
  })
}

// 获取弹窗选择数据列表
export const dataList = (data) => {
  return api({
    url: "/admin/index/dialog/index",
    method: 'post',
    data,
    notCancel: true
  })
}
// 获取弹窗选择捐款数据列表
export const dontaionList = (data) => {
  return api({
    url: "/admin/donation/donation/componentSearch",
    method: 'post',
    data,
    notCancel: true
  })
}

// 获取专题分类下拉选项
export const brandCategoryOptions = data => {
  return api({
    url: '/admin/cyc/brand_category/all',
    method: 'post',
    data,
    notCancel: true
  })
}

// 云相册数据类型选项
export const activityPhotoCategoryOptions = data => {
  return api({
    url: '/admin/cyc_activity/photo/allCategories',
    method: 'post',
    data,
    notCancel: true
  })
}

// 获取会员风采栏目选项
export const spiritColumnOptions = data => {
  return api({
    url: '/admin/cyc/spirit/allSpirits',
    method: 'post',
    data,
    notCancel: true
  })
}
// 获取会员风采栏目选项（协会互联版本）
export const spiritOptions = data => {
  return api({
    url: '/admin/cyc/spirit/spiritsList',
    method: 'post',
    data,
    notCancel: true
  })
}

// 获取会员风采分类选项
export const spiritCategoryOptions = data => {
  return api({
    url: '/admin/cyc/spirit/issuesList',
    method: 'post',
    data,
    notCancel: true
  })
}

// 获取个人中心快接入口选项
export const mePageLinkOptions = data => {
  return api({
    url: '/admin/app_design/link/otherSystems',
    method: 'post',
    data,
    notCancel: true
  })
}

// 个人主页快捷入口
export const memberIndexQuickEnterOptions = () => {
  return api({
    url: '/admin/app_design/link/memberQuickEnters',
    method: 'post',
    notCancel: true
  })
}

// 商协会业务数据列表
export const associationList = (data, notCancel = true) => {
  return api({
    url: '/admin/gov/component/searchProjects',
    method: 'post',
    data,
    notCancel
  })
}

export const getGovActivities = (data) => {
  return api({
    url: 'admin/gov/component/searchActivities',
    method: 'post',
    data,
    notCancel: true
  })
}

export const getGovEnterprises = (data, notCancel = true) => {
  return api({
    url: '/admin/cyc/companyData/componentSearch',
    method: 'post',
    data,
    notCancel
  })
}

export const channelsVideoList = (data, notCancel = true) => {
  return api({
    url: '/admin/wx_open/WxAccountVideo/componentSearch',
    method: 'post',
    data,
    notCancel
  })
}

export const channelsLiveList = (data, notCancel = true) => {
  return api({
    url: '/admin/wx_open/WxAccountLive/componentSearch',
    method: 'post',
    data,
    notCancel
  })
}

/* 视频号所有分类 */
export const allChannelsCategories = () => {
  return api({
    url: '/admin/wx_open/WxAccountCategory/all',
    method: 'post'
  })
}

// 在线学习分类选项数据
export const onlineLearningCategories = () => {
  return api({
    url: '/admin/classroom/course_category/all',
    method: 'post',
    notCancel: true
  })
}

// 在线学习业务数据
export const onlineLearningList = (data, notCancel = true) => {
  return api({
    url: '/admin/classroom/course/componentSearch',
    method: 'post',
    data,
    notCancel
  })
}
// 学院老师业务数据
export const getTeacherList = (data, notCancel = true) => {
  return api({
    url: '/admin/college/teacher/componentSearch',
    method: 'post',
    data,
    notCancel
  })
}
// 招聘岗位列表数据
export const getEmployList = (data, notCancel = true) => {
  return api({
    url: '/admin/employ/career/componentSearch',
    method: 'post',
    data,
    notCancel
  })
}
// 会刊列表
export const journalList = (data, notCancel = true) => {
  return api({
    url: '/admin/cyc/journal/index',
    method: 'post',
    data,
    notCancel
  })
}
// 会刊分类列表
export const journalYearList = (data, notCancel = true) => {
  return api({
    url: '/admin/cyc/journal/years',
    method: 'post',
    data,
    notCancel
  })
}

// 热门小组业务数据
export const hotGroupList = (data, notCancel = true) => {
  return api({
    url: '/admin/circle/group/componentSearch',
    method: 'post',
    data,
    notCancel
  })
}
// 列表形式展示所有上级组织接口
export const getParentToList = (data, notCancel = true) => {
  return api({
    url: '/admin/cyc/Organization/parentToList',
    method: 'post',
    data,
    notCancel
  })
}
// 所有小组业务数据
export const allGroupList = (data, notCancel = true) => {
  return api({
    url: '/admin/circle/group/all',
    method: 'post',
    data,
    notCancel
  })
}

// 热门话题业务数据
export const hotTopicList = (data, notCancel = true) => {
  return api({
    url: '/admin/circle/topic/componentSearch',
    method: 'post',
    data,
    notCancel
  })
}

// 社区达人业务数据
export const communityTalentList = (data, notCancel = true) => {
  return api({
    url: '/admin/circle/member/componentSearch',
    method: 'post',
    data,
    notCancel
  })
}

// 活动分类(协会互联)
export const allCategories = (data, notCancel = true) => {
  return api({
    url: '/admin/cyc_activity/activityCategory/allCategories',
    method: 'post',
    data,
    notCancel
  })
}
// 文章分类(协会互联)
export const articleCategoryAll = (data, notCancel = true) => {
  return api({
    url: '/admin/portal/category/allCategories',
    method: 'post',
    data,
    notCancel
  })
}
// 广告分类
export const bannerCategoryAll = (data, notCancel = true) =>{
  return api({
    url: '/admin/cyc/bannerCategory/all',
    method: 'post',
    data,
    notCancel
  })
  
} 
// 企业分类(协会互联)
export const companyCategoryAll = (data, notCancel = true) => {
  return api({
    url: '/admin/cyc/companyCategory/allCategories',
    method: 'post',
    data,
    notCancel
  })
}
// 课程分类(协会互联)
export const learnCategoryAll = (data, notCancel = true) => {
  return api({
    url: '/admin/classroom/courseCategory/allCategories',
    method: 'post',
    data,
    notCancel
  })
}
// 小商店分类(协会互联)
export const shopCategoryAll = (data, notCancel = true) => {
  return api({
    url: '/admin/shop/goods/allCategories',
    method: 'post',
    data,
    notCancel
  })
}

export const getCustomHeaderPeople = (data, notCancel = true) => {
  return api({
    url: '/admin/cyc/banner/userComponent',
    method: 'post',
    data,
    notCancel
  })
}
// 企业库信息展示选项
export const getCompanyLibOptions = (data, notCancel = true) => {
  return api({
    url: '/admin/cyc/companyData/infoComponentSelect',
    method: 'post',
    data,
    notCancel
  })
}
// 捐赠组件请求捐赠列表
export const getDonationList = (data, notCancel = true) => {
  return api({
    url: '/admin/donation/donation/componentSearch',
    method: 'post',
    data,
    notCancel
  })
}

/**
 * 数据报告-信息展示选项接口
 */
export const getAnalysisUserInfoOptions = () => {
  return api({
    url: '/admin/cyc/user/dataReportUserForm',
    method: 'post'
  })
}

/**
 * 商协会组件 组织类型
 */
export const getProjectsTypes = () => {
  return api({
    url: '/admin/gov/component/projectsTypes',
    method: 'post'
  })
}

/**
 * 商协会组件 关联平台
 */
export const govPlatform = () => {
  return api({
    url: '/admin/gov/govPlatform/componentSearch',
    method: 'post'
  })
}

// 获取图标
export const getIcon = (data) => {
  return api({
    url: '/admin/app_design/icon/componentIcons',
    method: 'post',
    data
  })
}
// 获取默认功能组件列表
export const getMineSystemNavs = (data) => {
  return api({
    url: '/admin/app_design/link/mineSystemNavs',
    method: 'post',
    data
  })
}
// 城市Json
export const getCityJson = (data) => {
  return api({
    url: '/admin/admin/data/getCityJson',
    method: 'post',
    data
  })
}