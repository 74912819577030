/*
 * @Author: mulingyuer
 * @Date: 2021-04-08 15:30:00
 * @LastEditTime: 2021-04-08 18:39:04
 * @LastEditors: mulingyuer
 * @Description:
 * @FilePath: \saas-admin-vue\src\modules\vote\router\index.js
 * 怎么可能会有bug！！！
 */
export default [
  {
    path: 'college',
    component: { render: (h) => h('router-view') },
    // meta: { activeMenu: "VoteList" },
    name: 'College',
    redirect: { name: 'CollegeTeacherList' },
    children: [
      {
        path: '/college_teacher_list',
        name: 'CollegeTeacherList',
        component: () => import('../views/college-teacher-list.vue'),
      },
      {
        path: '/teacherDetail/:id',
        name: 'AddCollegeTeacher',
        component: () => import('../views/teacher-detail'),
        meta: { title: '老师详情' },
      },
      {
        path: '/college-category-list',
        name: 'CollegeCategoryList',
        component: () => import('../views/learn-category-list'),
        meta: { title: '分类' },
      },
      {
        path: '/college_audio_list',
        name: 'CollegeAudioList',
        meta: { title: '音频列表' },
        component: () => import('../views/college-audio-list.vue'),
      },
      {
        path: '/audioDetail/:id',
        name: 'AddCollegeAudio',
        component: () => import('../views/audio-detail'),
        meta: { title: '音频详情' },
      }
    ],
  },
]
